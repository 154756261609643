.RP .RPI {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin: 2px;
  background-color: white;
  padding: none !important;
}
.RP .RPN {
  font-weight: bold;
  margin: none;
  padding-bottom: 0px;
}
.RP .RPSP {
  color: black;
}

.RP .RPP {
  color: #5ec401;
  font-size: 18px;
}
.RB {
  margin-top: 60px;
  border-radius: 50%;
  border: none;
  color: white;
  background-color: transparent;
  font-size: 20px;
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(3);
  }
  100% {
    transform: scale(1);
  }
}

.RB.clicked {
  animation: bounce 0.3s ease-in-out;
}
