.o_icon {
  background-color: rgba(243, 122, 32, 1);
  color: white;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.Order_no {
  font-weight: bold;
  font-size: 20px;
}

.Order_price {
  color: rgba(243, 122, 32, 1);
  font-weight: bold;
  font-size: 18px;
}

/* Order state colors */
.delivered {
  color: green;
}

.cancelled {
  color: red;
}

.Ordered {
  color: orange;
}
