.OrderType {
  color: black;
  font-size: 20px;
  width: 100%;
  border: none;
  background-color: transparent;
}

.OrderType:hover {
  color: #87dd39;
}
