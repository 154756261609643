/* Chat input container */
.chat-input-container {
  position: fixed;
  bottom: 20px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 30px;
}

/* Input field */
.chat-input {
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 16px;
  outline: none;
  margin-right: 10px;
}

.chat-input:focus {
  border-color: #0078d4;
  box-shadow: 0 0 5px rgba(0, 120, 212, 0.3);
}
.message {
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
  max-width: 70%;
}

/* Sender's message */
.message.sender {
  background-color: #66d200;
  margin-left: auto;
  color: white;
}

/* Receiver's message */
.message.receiver {
  color: white;
  background-color: rgba(35, 108, 217, 1);
}
/* Send button */
.send-button {
  background-color: #0078d4;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
}
