.ProfilePic > img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}
.about {
  margin-bottom: 0;
  font-size: 20px;
}
.about > .UserName {
  font-weight: bold;
}
