.AddressType {
  font-weight: bold;
  font-size: 20px;
}
.Addressbtn > .btnD,
.Addressbtn > .btnE {
  border-radius: 50%;
  font-size: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center; /* Center the text/icon */
  border: none; /* Remove default border */
  color: white;
}
.btnD {
  background-color: #5ec401;
}
.btnE {
  background-color: #ff5552;
}
