.location-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.location-icon {
  font-size: 24px;
}
.LocText {
  overflow: hidden;
  height: 50px;
}
.arrow {
  background: none;
  border: none;
  cursor: pointer;
}

.arrow .icon {
  font-size: 18px;
}
