.CartP{
    top: 2px;
}
.CartP .CartPI{
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    margin: 2px;
    background-color: white;
    padding: none !important;

}
.CartP .CartPN{
    font-weight: bold;
    font-size: 20px;

}
.CartP .CartPSP{
    color: black;
}

.CartP .CartPP{
    color:#F37A20;
    font-size: 20px;
}
.CCardBtn > .btn {
    font-size: 20px;
    width: 40px; 
    height: 40px; 
    display: flex;
    justify-content: center;
    align-items: center; /* Center the text/icon */
    border: none; /* Remove default border */
    color: white;
}
.CCardBtn > Span {
    color: black;
    font-size: 20px;
    margin: 0 5px;
}
.CCardBtn > .btnP {
    background-color:#5EC401 ;
}
.CCardBtn > .btnM {
    background-color:#FF5552 ;
}
