.OrderGIFContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.OrderGIFContainer * {
  text-align: center;
}
.OrderGIF {
  width: 50%;
}
