.ProCard {
  filter: drop-shadow(3px 5px 3px gray);
  margin: 6px 0px;
}
.PCard {
  height: 84%;
  background-color: rgb(255, 255, 255);
  border-radius: 12px 12px 0 0;
  display: flex;
  flex-direction: column;
}
.PCard > img {
}
.PCard > .PPrice {
  color: #f37a20;
  padding: 0 10px;
  overflow: hidden;
}
.PCard > .PName {
  font-size: 16px;
  color: black;
  padding: 0 10px;
  overflow: hidden;
}
.CB {
  width: 100%;
  border: 0px;
  background-color: #5ec401;
  border-radius: 0 0 12px 12px;
  padding: 8px 12px;
  color: white;
  font-size: 14px;
}
