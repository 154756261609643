.breadcrumb * {
  text-decoration: none;
  padding: 5px;
}
.breadcrumb {
  color: black;
  background-color: #87dd39;
  border-radius: 5px;
  width: fit-content;
}
#link {
  color: rgb(0, 0, 0); /* Change to your desired color */
}
.SearchDiv {
  background-color: transparent;
  transition: height 0.5s ease;
  display: none;
  margin-top: 16px;
}

.SearchDiv.active {
  display: block;
}

#bell {
  height: 25px;
  color: black;
}

.location-row {
  margin-top: 10px;
  text-decoration: none !important;
}
.location-icon {
  height: 30px;
  width: 30px;
  background-color: #87dd39;
  padding: 10px;
  border-radius: 50%;
}
.location-text {
  margin-bottom: 0;
  font-size: 20px;
}
.arrow {
  border: 0px;
  background-color: transparent;
}
.arrow .arrow > .icon {
  height: 25px;
}
