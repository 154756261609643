/* Toolbar.css */

.toolbar {
    background-color: white;
    border-radius: 15px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

.toolbar-button {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    padding: 10px;
    transition: background-color 0.3s ease;
}

.toolbar-button:hover,
.toolbar-button:active {
    background-color: #7ED321; /* Green color on hover and active */
}

.toolbar-icon {
    font-size: 24px;
    color: #606060;
}
