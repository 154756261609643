.OrderNo {
  font-weight: bold;
  font-size: 20px;
}
.OrderNo > .text-end {
  font-weight: 100;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.Noti {
  background-color: #ff5552;
  border-radius: 50%;
  font-size: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center; /* Center the text/icon */
  border: none; /* Remove default border */
  color: white;
}
