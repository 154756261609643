.profile_pic {
  width: 150px; /* Adjust the size as needed */
  height: 150px;
  border-radius: 50%; /* Makes the image circular */
  position: relative;
}

.edit-button {
  height: 50px;
  width: 50px;
  position: relative;
  font-size: 20px; /* Smaller text size for the button */
  border-radius: 50%; /* Rounded button */
  border: none;
  background-color: orange;
  top: 50px;
  right: 50px;
  color: white;
}
