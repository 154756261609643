.OrderConfirm {
  height: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.OrderConfirm > h1,
.OrderConfirm > h2 {
  text-align: center;
}

.Review > textarea {
  width: 100%;
  border-radius: 10px;
  padding: 4px 6px;
  font-size: 18px;
}
.Submit,
.Skip {
  border-radius: 10px;
  padding: 10px;
  border: none;
  font-size: 20px;
  width: 100%;
  margin: 10px 0;
}
.Submit {
  background-color: #5ec401;
  color: white;
}
.Skip {
  background-color: rgba(243, 122, 32, 1);
  color: white;
}
.Stars {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  font-size: 40px;
  color: #5ec401;
}
.StarActive {
  color: yellow; /* Highlight color for active stars */
}
