.AddButton {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.AddButton > button {
  width: 80%;
  background-color: rgba(54, 179, 126, 0.14);
  color: #5ec401;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
}
.lochead > .text-start {
  font-size: 20px;
}

.lochead > .text-end {
  font-size: 20px;
  color: #5ec401;
  text-decoration: underline;
}

.CLA {
  font-size: 20px;
}

.subtotal,
.delivery {
  font-size: 18px;
}
.total {
  font-size: 18px;
  font-weight: bold;
}

.OrderButton {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.OrderButton > button {
  width: 80%;
  background-color: #5ec401;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-size: 20px;
}
.Time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  height: 120px;
  margin: 10px 0;
}
.Time button {
  width: 32%;
  border-radius: 5px;
  border: 1px solid gray;
  padding: 12px 0px;
}
.Time button:hover {
  color: #5ec401;
  border: 2px solid #5ec401;
}
