.ProductD {
  height: 400px;
  background-color: white;
  border-radius: 10px 10px 0 0;
  margin-top: 10px;
}
.sub-image {
  margin: 20px 0 0 0;
  overflow: scroll;
  height: fit-content;
  gap: 5px;
}
.sub-image > img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border: 1px solid rgb(178, 178, 178);
  border-radius: 10px;
  margin: 2px;
}
.PN {
  color: black;
  font-size: 18px;
  font-weight: bold;
  background: #ffffff;
  padding: 8px 12px;
  border-radius: 0 0 10px 10px;
}
.PP > #quantity {
  font-weight: bold;
  font-size: 25px;
  color: black;
}
.PP > #price {
  font-weight: bold;
  font-size: 25px;
  color: #5ec401;
}
.PC {
  background-color: white;
  border-radius: 12px;
  padding: 10px 14px;
}
.PC > #CN,
#RN {
  color: black;
  /* text-decoration: underline; */
  border-bottom: 1px solid rgb(178, 178, 178);
  font-weight: bold;
  padding: 2px;
}

.PC > #CD {
  color: black;
}

.PO {
  display: flex;
  justify-content: center;
}
.PO > button {
  width: 50%;
  border: 0px;
  background-color: #5ec401;
  border-radius: 20px;
  padding: 8px 12px;
  color: white;
  font-weight: bold;
  font-size: 20px;
}
