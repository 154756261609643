/* Default styling for large screens (376px and above) */
.CCard {
  filter: drop-shadow(3px 5px 3px gray);
}
.Product {
  width: fit-content;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CImg {
  width: 120px;
  height: 120px;
  object-fit: cover;
  overflow: hidden;
}
.CName {
  font-size: 18px;
  color: #000;
  text-align: center;
  margin: auto;
  height: 60px;
  min-width: 122px;
  overflow: hidden;
}
